var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"shop"}},[_c('header',[_c('div',{staticClass:"headerTop"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("首页")])],1),_c('li',[_c('router-link',{attrs:{"to":"wisdom"}},[_vm._v("智量百业")])],1),_c('li',{staticClass:"cur"},[_c('router-link',{attrs:{"to":"shop"}},[_vm._v("同城好店")])],1),_c('li',[_c('router-link',{attrs:{"to":"assistant"}},[_vm._v("抖微助手")])],1),_c('li',[_c('router-link',{attrs:{"to":"flyfishoutlets"}},[_vm._v("飞鱼系统")])],1),_c('li',[_c('router-link',{attrs:{"to":"commerce"}},[_vm._v("跨境电商")])],1),_c('li',[_c('router-link',{attrs:{"to":"about"}},[_vm._v("关于我们")])],1)])])]),_c('img',{attrs:{"src":require("@/assets/images/shop_banner1.jpg"),"alt":""}})]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('h1',[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container pd100 shop"},[_c('div',{staticClass:"title"},[_c('h4',[_c('img',{attrs:{"src":require("@/assets/images/shop.jpg"),"alt":"同城好店"}}),_c('span',[_vm._v("同城好店")])]),_c('p',[_vm._v("同城3-5公里精准引流服务")])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"shop-bg"},[_c('img',{attrs:{"src":require("@/assets/images/shop-bg-1.png"),"alt":""}})]),_c('div',{staticClass:"item_circle item_circle1"},[_c('img',{attrs:{"src":require("@/assets/images/shop-1.jpg"),"alt":"工具服务"}}),_c('p',[_vm._v("抖音门店+同城好店")]),_c('p',[_vm._v("工具服务")])]),_c('div',{staticClass:"item_circle item_circle2"},[_c('img',{attrs:{"src":require("@/assets/images/shop-2.jpg"),"alt":"全城引流"}}),_c('p',[_vm._v("精准用户三天裂变")]),_c('p',[_vm._v("全城引流")])]),_c('div',{staticClass:"item_circle item_circle3"},[_c('img',{attrs:{"src":require("@/assets/images/shop-3.jpg"),"alt":"拍摄剪辑"}}),_c('p',[_vm._v("AI高质量短视频")]),_c('p',[_vm._v("拍摄剪辑")])]),_c('div',{staticClass:"item_circle item_circle4"},[_c('img',{attrs:{"src":require("@/assets/images/shop-4.jpg"),"alt":"设计支持"}}),_c('p',[_vm._v("个性化活动海报")]),_c('p',[_vm._v("设计支持")])]),_c('div',{staticClass:"item_circle item_circle5"},[_c('img',{attrs:{"src":require("@/assets/images/shop-5.jpg"),"alt":"分析运营"}}),_c('p',[_vm._v("抖音裂变数据")]),_c('p',[_vm._v("分析运营")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container pd60 shop_1"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":require("@/assets/images/title-icon.jpg"),"alt":""}}),_c('span',[_vm._v("你的门店是否还经历着？")])]),_c('div',{staticClass:"main"},[_c('img',{attrs:{"src":require("@/assets/images/shop-content.jpg"),"alt":""}}),_c('div',{staticClass:"item_circle item_circle1"},[_c('p',[_vm._v("营销成本高，超低价让利引流，")]),_c('p',[_vm._v("升单差，复购低？")])]),_c('div',{staticClass:"item_circle item_circle2"},[_c('p',[_vm._v("不懂拍短视频、不懂流量运营，")]),_c('p',[_vm._v("粉丝少，粘性差？")])]),_c('div',{staticClass:"item_circle item_circle3"},[_c('p',[_vm._v("线上媒体和平台广告投入贵，")]),_c('p',[_vm._v("引流效果不精准？")])]),_c('div',{staticClass:"item_circle item_circle4"},[_c('p',[_vm._v("线上促销活动效果差，")]),_c('p',[_vm._v("粉丝转化率低，到店顾客少？")])]),_c('div',{staticClass:"item_circle item_circle5"},[_c('p',[_vm._v("门店各种优惠活动传播效果美，")]),_c('p',[_vm._v("只影响小范围用户群？")])]),_c('div',{staticClass:"item_circle item_circle6"},[_c('p',[_vm._v("老顾客不断流失，新客源少且不稳定，")]),_c('p',[_vm._v("生意越来越难做？")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container pd100 shop_2"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":require("@/assets/images/title-icon.jpg"),"alt":""}}),_c('span',[_vm._v("整体解决方案详情")])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/shop_2-1.jpg"),"alt":""}}),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("根据商家情况开通抖音门店和同城好店")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("提供线上线下优惠活动服务支持")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("与线下实体店结合，同步高效引流裂变")])])])]),_c('div',{staticClass:"item"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v(" 根据店主用户画像及规则， "),_c('i',[_vm._v("进行AI高质量短视频拍摄&剪辑")])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v(" 独创视频裂变引流系统， "),_c('i',[_vm._v("1变300-1000、300-1000二次裂变引流")])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("提供个性化抖音推广海报设计")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("1对1的专业抖音运营指导")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop-icon.jpg"),"alt":""}}),_c('span',[_vm._v("提供运营推广数据分析指导")])])]),_c('img',{attrs:{"src":require("@/assets/images/shop_2-2.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pd60 shop_3"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/images/shop_3-content.jpg"),"alt":""}}),_c('div',{staticClass:"item item_1"},[_c('h4',[_vm._v("引流到店")]),_c('p',[_vm._v("到店顾客扫码领取优惠")])]),_c('div',{staticClass:"item item_2"},[_c('h4',[_vm._v("顾客领券")]),_c('p',[_vm._v("顾客抖音授权后自动领券")]),_c('p',[_vm._v("并查看推广视频")])]),_c('div',{staticClass:"item item_3"},[_c('h4',[_vm._v("顾客发布视频")]),_c('p',[_vm._v("顾客无需拍摄")]),_c('p',[_vm._v("一键直接发布高质量视频")])]),_c('div',{staticClass:"item item_4"},[_c('h4',[_vm._v("全城裂变")]),_c('p',[_vm._v("平均每个视频"),_c('i',[_vm._v("带来300-1000的播放量")])]),_c('p',[_vm._v("推送给好友及同城附近的人")]),_c('p',[_vm._v("新顾客通过视频获得折扣优惠")]),_c('p',[_vm._v("到店消费后继续转发全城裂变")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container pd100 shop_4"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":require("@/assets/images/title-icon.jpg"),"alt":""}}),_c('span',[_vm._v("适用场景")])]),_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop_4-1.jpg"),"alt":""}}),_c('p',[_vm._v("餐饮行业")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop_4-2.jpg"),"alt":""}}),_c('p',[_vm._v("美容行业")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop_4-3.jpg"),"alt":""}}),_c('p',[_vm._v("房地产行业")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop_4-4.jpg"),"alt":""}}),_c('p',[_vm._v("服装行业")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop_4-5.jpg"),"alt":""}}),_c('p',[_vm._v("酒店民宿")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop_4-6.jpg"),"alt":""}}),_c('p',[_vm._v("旅游景点")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop_4-7.jpg"),"alt":""}}),_c('p',[_vm._v("健身娱乐")])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/shop_4-8.jpg"),"alt":""}}),_c('p',[_vm._v("宠物店")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pd60 contact"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":require("@/assets/images/title-icon.jpg"),"alt":""}}),_c('span',[_vm._v("业务合作")])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/images/contact-2.png"),"alt":""}}),_c('p',[_vm._v(" 请将合作资料发送至邮箱：1487986688@qq.com 或者加微信：13410419943 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"main container pd60"},[_c('div',{staticClass:"item"},[_c('h4',[_vm._v("关于我们")]),_c('p',[_vm._v("公司介绍")]),_c('p',[_vm._v("业务合作")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("联系我们")]),_c('p',[_vm._v("邮箱：1487986688@qq.com")]),_c('p',[_vm._v("电话（微信同名）：13410419943，周一至周日 9:30 - 22:30")])]),_c('div',{staticClass:"wx"},[_c('div',{staticClass:"wx-item"},[_c('img',{attrs:{"src":require("@/assets/images/wx-1.jpg"),"alt":""}}),_c('p',[_vm._v("扫码关注公众号")])]),_c('div',{staticClass:"wx-item"},[_c('img',{attrs:{"src":require("@/assets/images/wx-2.jpg"),"alt":""}}),_c('p',[_vm._v("业务合作")])])])]),_c('div',{staticClass:"copyright container pd60"},[_c('p',[_vm._v("©2019-2024 深圳漠阳风科技有限公司 版权所有")]),_c('a',{attrs:{"href":"http://beian.miit.gov.cn/"}},[_vm._v("粤ICP备19157531号-2")])])])
}]

export { render, staticRenderFns }